export default {
  'task.title': 'Task',
  'task.annotation': 'Annotation',
  'task.audit': 'Review',
  'task.card.remain': 'Remaining {count}',
  'task.card.finish': 'I answered {count}',
  'task.card.other': 'There are no more questions in the current task, please select another task card',
  'task.card.end': 'Ended',
  'task.detail.upload': 'Upload attachment',
  'task.detail.upload.error1': 'File format error',
  'task.detail.upload.error2': 'Please keep the file size within 50 M',
  'task.detail.upload.desc':
    'Supported formats: image (png, jpg, jpeg, gif), video (mp4, mov), audio (mp3), document (txt, pdf, doc, docx, ppt, pptx, xlsx, xls); single file size does not exceed 50M.',
  'task.detail.submit': 'Submit',
  'task.detail.skip': 'Skip',
  'task.detail.cancel': 'Exit',
  'task.detail.cancel.desc': 'Exit directly, the current input content will not be preserved',
  'task.detail.label.result': 'Label Result',
  'task.detail.problematical': 'There is a problem with this question and it cannot be answered',
  'task.detail.kind.tips': 'Reminder',
  'task.detail.time.left': 'Only left until the end',
  'task.detail.time.left.desc':
    'If the timeout is not submitted, the question will be automatically recycled, and the filled content will not be saved',
  'task.detail.timeout.desc':
    'The current question has timed out and has been automatically collected. Please claim another question to continue answering~',
  'task.detail.error.required': 'Required fields are not filled in',
  'task.detail.audit.title': 'Review the left-side answer',
  'task.detail.audit.root.id': 'Source question ID',
  'task.detail.audit.question': 'Question',
  'task.detail.audit.question.id': 'Question ID',
  'task.detail.audit.annotator': 'Annotator',
  'task.detail.audit.auditor': 'Reviewer',
  'task.detail.audit.hint': 'Verify whether the left-side labeled result is accurate and meets the requirements.',
  'task.detail.audit.quicker': 'shortcut: Yes - Alt + 1; No - Alt + 2',

  'task.custom.question.title': 'Custom topic scope',
  'task.custom.question.desc': 'Please enter the question ID (field: Data_id) a question ID line',
  'task.custom.question.placeholder': 'One question ID per line',
  'task.custom.root.title': 'Custom source question scope',
  'task.custom.root.desc':
    'Please enter the source question ID (field: Questionnaire_id) One line of the source question ID',
  'task.custom.root.placeholder': 'One line of source question ID',
  'task.diff.hint': 'No changed content',
  'task.diff.title': 'Content comparison',
  'task.diff.shortcut.key': 'shortcut: expand/collapse',
  'task.diff.benchmark': 'Benchmark',
  'task.diff.contrast': 'contrast',

  'task.question.all': 'All topics',
  'task.question.all1': 'Annotated Questions',
  'task.question.all2': 'Reviewed Questions',
  'task.question.question': 'Only see marked as problematic',
  'task.question.scope': 'Custom topic scope',
  'task.question.placeholder':
    'Enter the source question ID (Questionnaire_id) you want to query, press Enter to search',
  'task.question.placeholder2': 'Enter the question ID (Data_id) you want to query, press Enter to search',
  'task.question.switch.sub': 'Switch subtopic',
  'task.question.switch.sub.placeholder': 'Select topic ID',
  'task.detail.save.desc':
    'If it times out or you click on “Skip, Exit”, the saved results will be cleared; please submit promptly.',

  'task.plugin': 'Plugin',
  'task.plugin.title1': 'For prompt',
  'task.plugin.title2': 'For dialogue',
  'task.plugin.title3': 'For questions in the conversation',
  'task.plugin.google': 'Google translation',
  'task.plugin.deepl': 'Deepl translation',
  'task.plugin.grammar.check': 'Syntax verification',

  'task.error.msg1': 'No questions marked as problematic',
  'task.error.msg2': 'No more questions',
  'task.error.msg3': 'Error location/Total number of characters',
  'task.error.msg4': 'Translation service is temporarily unavailable',
  'task.hint1': 'The number of unqualified questions is 0',
  'task.hint2': 'The number of questions not adopted is 0',
  'task.hint3': 'View Non-compliant Only',
  'task.hint4': 'View Unaccepted Only',
  'task.hint5': 'View source question combination',
  'task.hint6': 'This question has been marked as having a problem and cannot be answered.',
  'task.hint7': 'Please enter your username',

  'member.title': 'Member Management',
  'member.del.desc': 'Are you sure to remove this user from this team',
  'member.invite': 'Invite members',
  'member.team': 'Team',
  'member.team.join': 'Invite you to join the team',
  'member.team.join.success': 'You have joined, please go to the annotation page',
  'member.link.timeout': 'Link has timed out',
  'member.join': 'Join',
  'member.hint':
    'Tips: If a user has joined a team and then joins a new team, they will automatically be withdrawn from the previous team.',
  'member.operate.hint':
    'Operation mode: send the link to members, members click the link through the invitation, after joining the default for ordinary members',
  'member.copy.url': 'Copy link',
  'member.regenerate': 'Regenerate',
  'member.validity': 'Valid until',

  'common.copy': 'Copy',
  'common.copy.success': 'Copy success',
  'common.none': 'None',
  'common.save.success': 'Save successfully',
  'common.username': 'Username',
  'common.username.id': 'User ID',
  'common.role': 'Role',
  'common.operate': 'Operation',
  'common.save.placeholder': 'Save successfully',
  'common.preview': 'Preview',
  'common.check': 'View',
  'common.check.single': 'Single question viewing mode',
  'common.switch': 'Change the topic',
  'common.prev': 'Previous',
  'common.next': 'Next',
  'common.translation': 'Translation',
  'common.del': 'Remove',
  'common.sure': 'Sure',
  'common.cancel': 'Cancel',
  'common.search': 'Search',
  'common.save': 'Save',
  'common.personal.center': 'Individual center',
  'common.quit': 'Log out',
  'common.sort': 'Sort',
  'common.select': 'Please select',
  'member.super.admin': 'Super Administrator',
  'member.admin': 'Administrator',
  'member.regular': 'Regular Member',
  // 任务管理 v1.7
  'task.menu.name': 'My Tasks',
  'task.manage.title': 'Task Management',
  'task.manage.name': 'Task Name',
  'task.manage.status': 'Current Status',
  'task.manage.total.task': 'Total Annotations',
  'task.manage.total.task.user': 'Annotated Questions',
  'task.manage.total.audit': 'Total Reviews',
  'task.manage.remain': 'Remaining Questions',
  'task.manage.total.question.not.rate': 'Non-compliance Rate',
  'task.manage.remain.desc':
    'Remaining questions: If a question requires multiple answers, it is counted as multiple questions. However, each person can only claim a question once, so the remaining questions visible to each person differ. This shows the total remaining questions.',
  'task.manage.create.time': 'Creation Time',
  'task.manage.detail.id': 'Task ID',
  'task.manage.detail.title': 'Task Information',
  'task.manage.detail.stage': 'Stage',
  'task.manage.detail.stages': '{count} Review(s)',
  'task.manage.detail.progress': 'Progress Overview',
  'task.manage.detail.status': 'Task Status',
  'task.manage.detail.status.done': 'Ended',
  'task.manage.detail.status.open': 'Ongoing',
  'task.manage.detail.task.desc': 'Task Description',
  'task.manage.total.question.not.reach': 'Non-compliant Questions',
  'task.manage.total.question.not.reach2': 'Non-accepted Questions',
  'task.manage.total.question.not.reach2.desc':
    'For example, if a question requires three rounds of review, and two reviewers determine it meets the standard while this user determines it does not, then the question will not be accepted as meeting the standard.',
  'task.manage.total.question.not.desc':
    'Questions not meeting standards during associated reviews or manually rejected by operations.',
  'task.manage.total.question.remain': 'Remaining Questions',
  'task.manage.total.question.remain.desc':
    'Remaining questions: If a question requires multiple answers, it is counted as multiple questions. However, each person can only claim a question once, so the remaining questions visible to each person differ. This shows the total remaining questions.',
  'task.manage.total.audit.count': 'Reviewed Questions',
  'task.manage.total.audit.count.user': 'Reviewed Questions',
  'task.manage.total.audit.approved': 'Approved',
  'task.manage.total.audit.not.approved': 'Rejected',
  // v1.7.1
};
